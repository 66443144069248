<template>
  <div class="container-fluid pb-5">

    <div class="d-flex justify-content-between align-items-center mb-1">
      <h3 class="mb-0">ผลหวยหุ้นเวียดนาม</h3>
      <button class="btn btn-sm btn-success" @click="addRound">เพิ่มงวด</button>
    </div>
    <hr class="mt-0">

    <Row :key="index" v-for="(row, index) in rows" :data="row" @reload="loadData"  />

    <RoundModal :is-show="isShowRoundModal" @close="closeRoundModal" />
  </div>
</template>
<script>
import LottoService from '@/services/lottoService'

import Row from './components/Row'
import RoundModal from './components/RoundModal'

export default {
  components: {
    Row,
    RoundModal
  },
  data() {
    return {
      rounds: [],
      isShowRoundModal: false,
      interval: null
    }
  },
  computed: {
    rows() {
      return this.rounds
    }
  },
  methods: {
    loadData() {
      LottoService.getRounds().then((response) => {
        this.rounds = response.data
      })
    },
    addRound() {
      this.isShowRoundModal = true
    },
    closeRoundModal(needReload) {
      this.isShowRoundModal = false
      if(needReload) {
        this.loadData()
      }
    },
  },
  created() {
    this.loadData()

    this.interval = setInterval(this.loadData, 15000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>
<style lang="scss" scoped>
h3 {
  font-size: 20px;
}
</style>
